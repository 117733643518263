import React from 'react'
import BodyWrap from 'components/BodyWrap'
import ButtonContact from 'components/ButtonContact'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const {
    pretitle,
    pretitleHideLogo,
    title,
    body,
    bodyHtml,
    link,
    hasContactButton,
    buttonAddClass,
  } = content
  return (
    <section className='block-text-only'>
      <div className='container pt-20 md:pt-24 lg:pt-40 pb-20 md:pb-24 lg:pb-32'>
        {pretitle && (
          <Inview
            className={`eyebrow p5 line color--grey-4 mb-14 fade-in-up ${
              pretitleHideLogo ? 'hide-logo mb-8 lg:mb-10' : ''
            }`}
          >
            {pretitle}
          </Inview>
        )}
        <Inview className='grid lg:grid-cols-12'>
          <div className='lg:col-span-4 fade-in-up'>
            <h1 className='h1 smaller'>{title}</h1>
          </div>
          <div className='mt-6 lg:mt-0 lg:col-span-6 lg:col-start-6 fade-in-up'>
            <div className='body'>
              {body && <BodyWrap body={body} />}
              {bodyHtml && (
                <div
                  className='body'
                  dangerouslySetInnerHTML={{ __html: bodyHtml }}
                />
              )}
            </div>
            {link && link.to && (
              <div className='mt-8'>
                <ButtonLink to={link.to} text={link.text} />
              </div>
            )}
            {hasContactButton && (
              <div className='mt-10 lg:mt-8'>
                <ButtonContact addClass={buttonAddClass} />
              </div>
            )}
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default Component
