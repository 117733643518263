import React from 'react'
import { graphql } from 'gatsby'
import BlockTextOnly from 'components/blocks/BlockTextOnly'
import BlockBgImagePoints from 'components/blocks/BlockBgImagePoints'
import BlockLogoGridSimple from 'components/blocks/BlockLogoGridSimple'
import BlockTestimonials from 'components/blocks/BlockTestimonials'
import BlockCards from 'components/blocks/BlockCards'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const customers = data.allPrismicCustomer.nodes
  const customerShorts = data.allPrismicCustomerShort.nodes
  const secondaryLogos = data.allPrismicLogos.nodes[0].data.secondary_logos
  const content = {
    blockTextOnly: {
      // pretitle: `商户案例`,
      // pretitleHideLogo: true,
      title: `您做外卖的
      更优合作伙伴`,
      bodyHtml: `<p>星选闪电的合作伙伴渗透在餐饮产业链的各个环节。不论您是个体创业者，还是连锁品牌的加盟商，亦或是自有品牌拓展至全国市场，星选闪电都致力于为您提供外卖餐饮更具拓展性的解决方案。</p>`,
      hasContactButton: true,
      buttonAddClass: 'reserve_case',
    },
    testimonials: {
      customers: customerShorts.map((customer) => {
        return {
          restaurant: customer.data.title.text,
          name: customer.data.subtitle.text,
          gatsbyImage: customer.data.image,
          video: customer.data.video.text,
          buttonPrefix: 'case_video',
        }
      }),
    },
    cards: {
      cards: customers.map((customer, i) => {
        return {
          link: `/customers/${customer.uid}/`,
          gatsbyImage: customer.data.image,
          text: {
            // pretitle: '商户案例',
            title: customer.data.title.text,
            excerpt: customer.data.excerpt.text,
          },
          addClass: `case_${i + 1}`,
        }
      }),
    },
    bgImagePoints: {
      image: 'image--noodles-cbad8e72534859ad7e4ed4445c8790f4.jpg',
      text: {
        section: '加盟流程',
        title: `星选闪电
        <span>服务于</span>`,
        points: [
          {
            number: '01',
            pretitle: '找铺子',
            title: '个体创业者',
            body: `<p>您自己有确定要做的餐饮品牌。</p>
            <p>让星选闪电帮您快速选址，搭建属于您的外卖运营模型，触达更多潜在消费者，帮您的品牌快速成长。</p>`,
          },
          {
            number: '02',
            pretitle: '找品牌',
            title: '加盟投资者',
            body: `<p>您想要加盟一家已有餐饮品牌，寻求加盟。</p>
              <p>星选闪电帮您一站式解决选铺子+选品牌的纠结。为餐饮新手严选合作的餐饮品牌。每个合作品牌都是严格核算单店盈利模型，通过“点位数据+品牌属性”的匹配原则，力求给您提供靠谱选择。</p>`,
          },
          {
            number: '03',
            pretitle: '品牌扩张',
            title: '连锁品牌负责人',
            body: `<p>您的品牌需要拓展至全国。</p>
            <p>星选闪电全国统一的智能选址标准，帮您在迅速铺开全国点位的同时，提供更安心的线下督导运营服务。让您不必事事亲力亲为，有星选闪电为您一站搞定。</p>`,
          },
        ],
      },
    },
    logos: secondaryLogos,
  }
  const seoContent = {
    title: `商户案例`,
  }

  return (
    <div>
      <Seo content={seoContent} />
      <BlockTextOnly content={content.blockTextOnly} />
      <BlockBgImagePoints content={content.bgImagePoints} />
      <BlockLogoGridSimple logos={content.logos} />
      <BlockTestimonials content={content.testimonials} />
      <BlockCards content={content.cards} addClass='no-pad-top' />
      <BlockContact />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query {
    allPrismicCustomer(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allPrismicCustomerShort(
      sort: { order: DESC, fields: last_publication_date }
    ) {
      nodes {
        data {
          title {
            text
          }
          subtitle {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            thumbnails {
              larger {
                gatsbyImageData
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          video {
            text
          }
        }
      }
    }
    allPrismicLogos {
      nodes {
        data {
          secondary_logos {
            logo {
              gatsbyImageData
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
