import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Image from 'components/Image'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Component = ({ content, addClass }) => {
  const { title, cards } = content

  return (
    <section className={`block-cards bg--grey-2 ${addClass ? addClass : ''}`}>
      <div className='container'>
        {title && (
          <Inview className='title'>
            {/* <div className='eyebrow p5 line color--grey-4 mb-10 lg:mb-0 fade-in-up'>
              {title}
            </div> */}
            <h1 className='h1 smaller fade-in-up'>{title}</h1>
          </Inview>
        )}
        <div className='cards'>
          {cards.map((card, i) => (
            <Card key={i} content={card} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Component

const Card = ({ content }) => {
  const { link, image, gatsbyImage, text, addClass = '' } = content
  return (
    <Inview className='card'>
      <LinkWrap className={`fade-in-up ${addClass}`} to={link}>
        <div className='image'>
          {image && <Image filename={image} />}
          {gatsbyImage && <ImageWrap image={gatsbyImage} />}
        </div>
        <div className='text fade-in'>
          <div className='upper'>
            <h6 className='h6'>{text.pretitle}</h6>
            <h4 className='h4'>{text.title}</h4>
            <p className='small'>{text.excerpt}</p>
          </div>
          <div className='arrow arrow-bar is-right' />
        </div>
      </LinkWrap>
    </Inview>
  )
}
