import React from 'react'
import ModalVideo from 'components/ModalVideo'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

class BlockTestimonials extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      videoSrcs: [],
    }
    this.setSelected = this.setSelected.bind(this)
  }

  componentDidMount() {
    const customers = this.props.content.customers
    const customerVideos = customers.map((customer) =>
      customer.video ? `/videos/${customer.video}` : null
    )
    this.setState({ videoSrcs: customerVideos })
  }

  setSelected(id) {
    this.setState({
      selected: id,
    })
    const video = document.querySelector('.block-testimonials video')
    if (id !== null && this.state.videoSrcs[id]) {
      document.querySelector('html').classList.add('no-scroll')
      document.querySelector('.nav--holder').classList.add('hide')
      setTimeout(() => {
        document.querySelector('.nav--holder').classList.add('up')
      }, 300)
      video.src = this.state.videoSrcs[id]
      video.play()
    } else {
      document.querySelector('html').classList.remove('no-scroll')
      document.querySelector('.nav--holder').classList.remove('hide')
      document.querySelector('.modal--video video').classList.add('loading')
      video.pause()
    }
  }

  render() {
    const { customers } = this.props.content
    return (
      <section className='block-testimonials bg--grey-2'>
        <div className='container pt-20 pb-20 md:pt-24 md:pb-24 lg:pt-40 lg:pb-32'>
          <div className='grid grid-cols-4 md:grid-cols-12'>
            <div className='col-span-6 hidden md:flex'>
              <div
                className={`btn--card btn--card--large ${customers[0].buttonPrefix}1`}
                onClick={() => this.setSelected(0)}
              >
                <Inview>
                  <div className='btn--card--image  fade-in-up'>
                    <div className='btn--card--bg btn--card--bg-lg image-bg'>
                      <ImageWrap
                        image={customers[0].gatsbyImage.thumbnails.larger}
                      />
                    </div>
                  </div>
                </Inview>
              </div>
            </div>
            <div className='col-span-4 md:col-span-5 md:col-start-8 md:pt-6 block-right'>
              <Inview>
                <div className='fade-in-up'>
                  <div className='eyebrow line color--grey-4 mb-10 lg:mb-12'>
                    合作伙伴
                  </div>
                  <h2 className='h2 color--primary mb-10'>听听他们怎么说</h2>
                  <p className='mb-0'>那些年，与星选闪电共同成长的品牌。</p>
                </div>
              </Inview>
              <Inview>
                <div className='hidden md:flex'>
                  <div className='flex items-center mt-10 fade-in-up'>
                    <div className='mr-6'>
                      <a
                        className={`btn--play color--primary ${customers[0].buttonPrefix}1`}
                        onClick={() => this.setSelected(0)}
                      >
                        <svg
                          width='14'
                          height='19'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M13.756 9.503L.669 18.379V.627l13.087 8.876z'
                            fill='#fff'
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <p className='color--primary mb-0 md'>
                        {customers[0].restaurant}
                      </p>
                      <p className='small mb-0'>{customers[0].name}</p>
                    </div>
                  </div>
                </div>
              </Inview>
            </div>
            <div className='col-span-4 md:col-span-12 mt-16 lg:mt-20 scrolling--holder'>
              <div className='flex scrolling'>
                {customers.map((item, i) => (
                  <ScrollCard
                    key={i}
                    i={i}
                    item={item}
                    setSelected={this.setSelected}
                  />
                ))}
                <div className='spacer md:hidden' />
              </div>
            </div>
            <div className='md:hidden col-span-3 mt-12 hide-for-later'>
              <a
                className='btn--rounded color--white btn--bg btn--bg--primary inline-block'
                direction='up'
                duration='1'
                bg='#E6E5DE'
                href='/customers/'
              >
                See All Stories
              </a>
            </div>
            {/* <div className='hidden col-span-12 mt-20  text-center md:block'>
            <a
              className='btn--rounded color--grey-6 btn--bg btn--bg--grey-3 inline-block'
              direction='up'
              duration='1'
              bg='#E6E5DE'
              href='/customers/'
            >
              View All
            </a>
          </div> */}
          </div>
        </div>
        <ModalVideo
          selected={this.state.selected}
          setSelected={this.setSelected}
        />
      </section>
    )
  }
}

export default BlockTestimonials

const ScrollCard = ({ item, i, setSelected }) => {
  return (
    <div className={`btn--card ${i === 0 ? 'md:hidden' : ''}`}>
      <Inview>
        <div className='fade-in-up'>
          <div
            className={`btn--card--image ${item.buttonPrefix}${i + 1}`}
            onClick={() => setSelected(i)}
          >
            <div className='btn--card--bg btn--card--bg-1 image-bg'>
              <ImageWrap image={item.gatsbyImage} />
            </div>
            <div className='btn--card--play'>
              <svg
                width='11'
                height='14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M10.5 7l-10 6.5V.5l10 6.5z'
                  fill='#71706A'
                ></path>
              </svg>
            </div>
          </div>
          <p className='color--primary mb-0 mt-4 md'>{item.restaurant}</p>
          <p className='small'>{item.name}</p>
        </div>
      </Inview>
    </div>
  )
}
