import React from 'react'
import Image from 'components/Image'
import ButtonContact from 'components/ButtonContact'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { image, text } = content
  return (
    <section className='block-bg-image-points relative'>
      <Top content={text} />
      <Inview
        triggerOnce={true}
        className='image--holder desktop hidden lg:block z-0 relative md:absolute right-0 top-0 fade-in stagger-600'
      >
        <div className='image-bg'>
          <Image filename={image} />
        </div>
      </Inview>
      <Inview triggerOnce={true}>
        <div className='lg:hidden relative z-20 video-mobile-wrap blue-to-grey'>
          <div className='image--holder mobile fade-in relative'>
            <div className='image-bg'>
              <Image filename={image} />
            </div>
          </div>
        </div>
      </Inview>
    </section>
  )
}

export default Component

const Top = ({ content }) => {
  const { section, title, points } = content
  return (
    <div className='container top z-10 relative flex items-center'>
      <div className='grid lg:grid-cols-12'>
        <Inview className='text-block lg:col-span-10 bg--primary fade-in-up '>
          <div className='top'>
            {section && (
              <div className='eyebrow p5 line color--grey-1 fade-in-up'>
                {section}
              </div>
            )}
            {title && (
              <h1
                className='h1 fade-in-up'
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </div>
          <div className='points'>
            {points.map((point, i) => {
              return (
                <div key={i} className={`point fade-in-up stagger-${i * 100}`}>
                  <h3 className='h3 small'>{point.number}</h3>
                  <h5 className='h5'>{point.pretitle}</h5>
                  <hr />
                  {point.title && <h4 className='h4'>{point.title}</h4>}
                  <div
                    className='body small'
                    dangerouslySetInnerHTML={{ __html: point.body }}
                  />
                </div>
              )
            })}
          </div>
        </Inview>
      </div>
    </div>
  )
}
