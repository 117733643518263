import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import LinkWrap from 'components/LinkWrap'

const Component = ({ logos }) => {
  return (
    <div className='block-logo-grid-simple'>
      <div className='container'>
        <Inview className='logo grid gap-x-4 gap-y-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8'>
          {logos.map((logo, i) => (
            <Logo key={i} i={i} logo={logo} />
          ))}
        </Inview>
      </div>
    </div>
  )
}

export default Component

const Logo = ({ logo, i }) => {
  if (logo.link) {
    return (
      <LinkWrap to={logo.link}>
        <LogoInner logo={logo} i={i} />
      </LinkWrap>
    )
  } else {
    return <LogoInner logo={logo} i={i} />
  }
}

const LogoInner = ({ logo, i }) => {
  return (
    <div
      className='logo bg-white fade-in-up'
      style={{ transitionDelay: `${i * 50}ms` }}
    >
      <ImageWrap image={logo.logo} />
    </div>
  )
}
